<template>
  <div class="superCashback" ref="superCashback">
    <Loading :click="click"></Loading>
    <!--    <div class="cashback-share">-->
    <!--      <img src="https://cdn.cailu88.com/jingxuanshi/cashback-share.png"/>-->
    <!--    </div>-->
    <div class="search-module">
      <img class="search-logo" src="https://cdn.cailu88.com/jingxuanshi/cashback_search.png"/>
      <van-field class="search-content" placeholder="搜索京东商品，领取补贴红包" @click="handleJumpSearch"></van-field>
    </div>
    <div class="superCashback-banner">
      <img src="https://cdn.cailu88.com/jingxuanshi/cashback_banner.png"/>
    </div>
    <div class="superCashback-goods-list">
      <template v-for="(item, index) in goodsList">
        <div class="goods-wrap" :key="index">
          <div class="goods-image">
            <img v-lazy="item.pictUrl">
          </div>
          <div class="goods-info">
            <div class="goods-title">
              {{ item.itemTitle }}
            </div>
            <div class="goods-discounts">
              <div class="vouchers-one" v-if="item.couponValue">
                <span class="vouchers-text">券</span>
                <span class="vouchers-number">{{ item.couponValue }}元 </span>
              </div>
              <div v-if="item.bonusValue>0" class="vouchers-two">
                <span class="price-text">补贴</span>
                <span class="price-number">{{ item.bonusValue }}元</span>
              </div>
            </div>
            <div class="goods-price">
              <div>
                <p class="subsidies-price">补贴价 ¥ <span class="price">{{ item.zkFinalPrice }}</span></p>
                <p class="grayfont">市场价 <span class="line-text">¥ {{ item.reservePrice }}</span></p>
              </div>
              <div class="button">
                <span @click="go(item)">马上抢<van-icon name="arrow"/></span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div :class="['loading-wrap', goodsList.length < 5 ? 'loading-wrap-white':'']">
        <img v-if="goodsList.length < totalCount && totalCount !== 0" class="loading"
             src="https://cdn.cailu88.com/jingxuanshi/loading@2x.png"/>
        <p v-if="goodsList.length === totalCount && totalCount !== 0">
          暂无更多
        </p>
      </div>
      <div class="empty">
        <span class="line"></span>
        <p class="empty-text">找不到喜欢的商品？试着搜索一下吧</p>
        <span class="line"></span>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Field, Icon, Lazyload, Toast } from 'vant'
import Loading from '../../components/loading'
import baseUrl from '@/utils/baseUrl'
import h5AppLink from '@/utils/h5AppLink'

const api = require('../../utils/api').api

Vue.use(Field)
Vue.use(Icon)
Vue.use(Lazyload, {
  lazyComponent: true,
})
export default {
  name: 'superCashback',
  data () {
    return {
      token: '',
      version: '',
      channel: '',
      id: '',               //test
      page: 1,
      pageSize: 20,
      totalCount: 0,
      goodsList: [],
      click: false,
    }
  },
  components: {
    Loading
  },
  created () {
    this.token = this.$route.query.token
    this.version = this.$route.query.version
    this.channel = this.$route.query.channel
    this.appClient = this.$route.query.appClient
    this.id = this.$route.query.id
    this.handleGetGoodsList()
  },
  mounted () {
    window.addEventListener('scroll', this.lazyLoading) // 滚动到底部，再加载的处理事件
  },
  destroyed () {
    window.removeEventListener('scroll', this.lazyLoading)
    //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题
  },
  methods: {
    // 马上抢
    go (item) {
      let that = this
      if (!this.click) {
        return false
      }
      this.click = false
      this.$axios('/item/bonus/send/v2', {
        params: {
          cilentType: 2,
          itemId: item.itemId,
          itemSource: item.itemSource,
          isPG: item.isPG,
          couponClickUrl: item.couponClickUrl,
          commission: item.maxCommissionValue || '',
          loginType: 2
        }
      }).then(res => {
        let event = 'jumpJD'
        let param = {
          url: res.data.data.spreadUrl,
        }
        h5AppLink(event, param)
        that.click = true
      }).catch(err => {
        Toast.fail(err.data.message)
        that.click = true
      })
    },
    // 滚动加载商品
    lazyLoading () {
      // 获取 可视区高度`、`滚动高度`、`页面高度`
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let clientHeight = document.documentElement.clientHeight
      let scrollHeight = document.documentElement.scrollHeight
      if (scrollTop + clientHeight >= scrollHeight) { // 滚动到底部，逻辑代码
        //事件处理
        this.page++
        if (this.goodsList.length < this.totalCount && this.totalCount !== 0) {
          this.handleGetGoodsList('add')
        }
      }
    },
    // 获取商品信息
    handleGetGoodsList (type) {
      this.click = false
      this.$axios(api.superCashback, {
        params: {
          activityId: this.id,
          loginType: 1,
          clientType: 2,
          itemSource: 2,
          activityType: 6,
          page: this.page,
          pageSize: this.pageSize,
        }
      }).then(res => {
        // res.data.data.data ? res.data.data.data.map(item => {
        //   item.sum = (item.couponValue + item.bonusValue).toFixed(2)
        // }) : [];
        this.goodsList = type == 'add' ? [...this.goodsList, ...res.data.data.data] : res.data.data.data
        this.totalCount = res.data.data.totalCount
        this.click = true
      }).catch(err => {
        console.info(err)
        this.click = true
      })
    },
    // 跳转search页
    handleJumpSearch () {
      this.$router.push({
        name: 'cashbackSearch',
        query: {
          token: this.token,
          version: this.version,
          channel: this.channel,
          appClient: this.appClient
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
.superCashback {
  position: relative;
  min-height: 100vh;

  .cashback-share {
    & > img {
      width: 68px;
      height: 68px;
      position: fixed;
      bottom: 258px;
      right: 0px;
      z-index: 2;
    }
  }

  .search-module {
    position: sticky;
    top: 0;
    z-index: 10;
    background: #F2531C;
    padding: 10px 0;

    .search-logo {
      position: absolute;
      z-index: 2;
      top: 20px;
      left: 20px;
      width: 16px;
      height: 16px;
    }

    .search-content {
      margin: 0 9px;
      width: 95%;
      flex: 1;
      height: 35px;
      font-size: 15px;
      padding-left: 40px;
      color: #B6B6B6;
      display: flex;
      align-items: center;
      background: #FFFFFF;
      border-radius: 18px;
    }
  }

  .superCashback-banner {
    background: #F2531C;

    & > img {
      width: 100%;
    }
  }

  .superCashback-goods-list {
    background: #E5101E;
    min-height: 514px;
    width: 100%;
    padding: 8px 0 33px 0;

    .goods-wrap {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0px 8px 8px 8px;
      padding: 10px;
      max-height: 140px;
      background: #fff;
      border-radius: 8px;

      .goods-image {
        & > img {
          width: 120px;
          height: 120px;
          border-radius: 8px;
        }
      }

      .goods-info {
        margin-left: 10px;

        .goods-title {
          font-size: 14px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          color: #333333;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;

        }

        .goods-discounts {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 20px;
          font-size: 12px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          /* color: #8b7f08; */
          line-height: 20px;
          margin-top: 3px;

          .vouchers-one {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 16px;
            line-height: 16px;
            color: #E44A59;
            margin-right: 7px;

            .vouchers-text {
              width: 16px;
              height: 16px;
              font-size: 10px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              text-align: center;
              color: #fff;
              border-radius: 2px 0 0 2px;
              background-color: #E44A59;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 2;
            }

            .vouchers-text::after {
              content: '';
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: #fff;
              position: absolute;
              top: 50%;
              right: -3px;
              transform: translate(0, -50%);
              z-index: 2;
            }

            .vouchers-number {
              height: 16px;
              font-size: 11px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              padding: 0 4px;
              display: flex;
              border: 1px solid #E44A59;
              border-left: none;
              border-radius: 0 2px 2px 0;
              box-sizing: border-box;
              justify-content: center;
              align-items: center;
              overflow: hidden;
            }
          }

          .vouchers-two {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 16px;
            line-height: 16px;
            box-sizing: border-box;

            .price-text {
              font-size: 10px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              height: 16px;
              padding: 0 4px;
              color: #fff;
              background-color: #E44A59;
              border-radius: 2px 0 0 2px;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
            }

            .price-gray {
              font-size: 10px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              height: 16px;
              padding: 0 4px;
              color: #fff;
              background-color: #999999;
              border-radius: 2px 0 0 2px;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
            }

            .price-number {
              font-size: 11px;
              height: 16px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              padding: 0 2px;
              color: #E44A59;
              border: 1px solid #E44A59;
              box-sizing: border-box;
              border-radius: 0 2px 2px 0;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
            }
          }

        }

        .goods-price {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .subsidies-price {
            height: 17px;
            font-size: 12px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #E44A59;
            line-height: 17px;
            line-height: 17px;

            .price {
              height: 28px;
              font-size: 20px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: bold;
              color: #e44a59;
              line-height: 28px;
            }
          }

          .grayfont {
            margin-top: 5px;
            height: 17px;
            font-size: 12px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #999999;
            line-height: 17px;

            .line-text {
              height: 17px;
              font-size: 12px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #999999;
              line-height: 17px;
              text-decoration: line-through;
            }
          }

          .button {
            display: inline-block;
            padding: 4px 7px 4px 13px;
            line-height: 20px;
            background: #E44A59;
            border-radius: 4px;

            & > span {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-weight: 500;
              color: #ffffff;
              vertical-align: center;

              .van-icon {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .empty {
      margin-top: 18px;
      display: flex;
      align-items: center;
      justify-content: center;

      .line {
        display: block;
        width: 20px;
        background: #ffffff;
        height: 1px;
      }

      .empty-text {
        padding: 0 19px;
        background: #E5101E;
        font-size: 12px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 17px;
      }
    }

    .loading-wrap {
      font-size: 12px;
      line-height: 17px;
      color: #b6b6b6;
      text-align: center;
      padding: 15px 0;

      .loading {
        width: 35px;
        height: 35px;
        animation: loading 1s linear 0s infinite;
      }

      @keyframes loading {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      & > p {
        color: #fff;
      }
    }

    .loading-wrap-white {
      margin-top: 6px;
      background-color: #E5101E;
    }
  }
}
</style>
